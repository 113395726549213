import React from 'react';

export type Loading = 'eager' | 'lazy';

interface PictureSource extends React.SourceHTMLAttributes<HTMLSourceElement> {}

export interface ImageProps {
  alt?: string;
  className?: string;
  pictureStyles?: string;
  decoding?: 'async' | 'auto' | 'sync';
  height?: number;
  loading?: Loading;
  src: string;
  srcSet?: string;
  sources?: PictureSource[];
  width?: number;
  base64?: string;
}

export default function Image({
  alt = '',
  className,
  decoding,
  height,
  loading,
  src,
  srcSet,
  sources,
  width,
  base64,
  pictureStyles,
}: ImageProps) {
  const backgroundImage = `url(${base64})`;

  return (
    <picture className={pictureStyles} style={base64 ? {backgroundImage} : {}}>
      {sources?.map((source, index) => {
        return (
          <source
            key={index}
            srcSet={source.srcSet}
            media={source.media}
            width={source.width}
            height={source.height}
            type={source.type}
            sizes={source.sizes}
          />
        );
      })}
      <img
        alt={alt}
        className={className}
        decoding={decoding}
        height={height}
        loading={loading}
        src={src}
        srcSet={srcSet}
        width={width}
      />
    </picture>
  );
}
